<template>


    <div class="section-content">

        <div class="primary-section-content">
            <div class="main-section-heading">
                <h1 class="main-section-header">Aging</h1>
                <div class="title-section-settings">

                    <pagination
                            v-model="filters.page"
                            :limit="filters.limit"
                            :offset="filters.offset"
                            :result_count="result_count"
                            @input="buildQuery"
                    ></pagination>


                    <button class="w-button btn-sm secondary-btn" @click="showAside = !showAside">
                        <span v-show="showAside">
                            <span class="icon" ></span>&nbsp; Hide Filters
                        </span>
                        <span v-show="!showAside">
                            Show Filters  &nbsp;<span class="icon" v-show="!showAside"></span>
                        </span>
                    </button>

                </div>
            </div>

            <div class="content-view">



                <full-table
                        rowClass="clickable"
                        @filter="buildQuery"
                        :filters="filters"
                        :tableData="invoices"
                        :cols="cols"
                >

                    <template slot="address_data" slot-scope="a" >
                      <span @click="viewInvoice(a.data)">
                          <span class="unit" v-show="a.data.unit_number">Space #{{a.data.unit_number}}</span><br />
                          {{a.data.address}} <br />
                          <span class="subdued">{{a.data.city}} {{a.data.state}} {{a.data.zip}}</span>
                      </span>
                    </template>

                  <template slot="tenant_name_data" slot-scope="a">
                    <span @click="viewContact(a.data)">
                    {{a.data.tenant_name}}
                    </span>
                  </template>

                    <template slot="due_data" slot-scope="data">
                      {{data.value | formatLocalDate}}<br />
                      <span class="subdued">{{data.value | timeAgo}}</span>
                    </template>

                    <template slot="balance_data" slot-scope="inv">
                        <span class="emergency">{{inv.value | formatMoney}}</span>
                    </template>

                </full-table>
            </div>
        </div>

        <div class="aside" :class="{hide: !showAside}">
            <div class="aside-header">
                <div>
                    <h2 class="heading-3">Filter Aging</h2>
                    <button class="w-button close-btn" @click="showAside = false">
                        <span class="icon"></span>
                    </button>
                </div>
            </div>



            <div class="aside-summary-section">
                <div class="aside-summary-row vertical" >
                    <label for="property_id" class="aside-summary-label">
                        Property
                    </label>
                    <div class="form-block w-form">
                        <multi-select
                                name="property_id"
                                :options="properties"
                                v-model="filters.search.property_id"
                                id="property_id"
                                idField="id"
                                emptyPlaceholder="Choose Properties"
                                itemName="properties"
                                labelField="address"
                                showAll
                        >

                            <template slot="option" slot-scope="props">
                                        <span class="property-list-result">
                                            {{props.data.address}}<br />
                                            <span class="subdued">{{props.data.city}} {{props.data.state}} {{props.data.zip}}</span>
                                        </span>
                            </template>
                        </multi-select>
                    </div>
                </div>
            </div>
            <div class="aside-summary-section">
                <div class="aside-summary-row vertical" >

                    <label for="start_date" class="aside-summary-label">
                        Period Start Date
                    </label>
                    <div class="form-block w-form">
                        <div>
                            <datepicker
                                format="MMM dd yyyy"
                                name="start_date"
                                v-model="filters.search.start_date"
                                wrapper-class="date-wrapper"
                                input-class="input light w-input date-input" ></datepicker>
                        </div>


                    </div>
                </div>

                <div class="aside-summary-row vertical" >

                    <label for="end_date" class="aside-summary-label">
                        Period End Date
                    </label>
                    <div class="form-block w-form">
                        <div>
                            <datepicker
                                format="MMM dd yyyy"
                                name="end_date"
                                v-model="filters.search.end_date"
                                wrapper-class="date-wrapper"
                                input-class="input light w-input date-input" ></datepicker>
                        </div>


                    </div>
                </div>
            </div>

        </div>

<!--        <slide-out-window :class="{hide: !showView}" @close="showView = false">-->
<!--            <display-invoice-->
<!--                    v-if="selected.id"-->
<!--                    :invoice_id="selected.id"-->
<!--                    slot="body"-->
<!--                    @close="showView = false"-->
<!--                    @saved="search"-->
<!--            >-->
<!--            </display-invoice>-->
<!--        </slide-out-window>-->

    </div>

</template>

<script type="text/babel">

    import Modal from '../assets/Modal.vue';
    import Status from '../includes/Messages.vue';
    import api from '../../assets/api.js';
    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';

    import DisplayInvoice from '../invoices/DisplayInvoice.vue';
    import SlideOutWindow from '../includes/SlideOutWindow.vue';


    import Loader from '../assets/CircleSpinner.vue'
    import Pagination from '../assets/Pagination.vue';
    import MultiSelect from '../assets/MultiSelect.vue';
    import { tableFilters } from '../../mixins/urlParser.js';
    import FullTable from '../assets/FullTable.vue';
    import { tableKeys } from '../../mixins/tableColumns/aging.js';
    import { EventBus } from '../../EventBus.js';




    export default {
        name: "AgingDetail",
        data() {
            return {
                result_count: 0,
                invoices:[],
                showAside: false,
                properties:[],
                showView: false,
                selected: {}
            }
        },
        components:{
            Modal,
            Status,
            Datepicker,
            Pagination,
            FullTable,
            MultiSelect,
            Loader,
            DisplayInvoice,
            SlideOutWindow

        },
        created (){
            this.buildQuery();
            this.getProperties();
            EventBus.$on('invoice_saved', this.search);
            EventBus.$on('lease_payment_saved', this.search);
        },
        destroyed(){
            EventBus.$off('invoice_saved', this.search);
            EventBus.$off('lease_payment_saved', this.search);
        },
        filters:{
            timeAgo(time){
                if(!time) return '';
                return moment(time).fromNow();
            }
        },
        computed:{
        },
        methods:{
            getProperties:  function(){
                api.get(this, api.PROPERTIES + 'list').then(response => {
                    this.properties = response.properties;
                });
            },
            viewContact(data){
                this.$store.dispatch('navigationStore/openSlideOut', {
                    component: 'contact',
                    props: {
                        contact_id: data.tenant_id,
                        view: 'contact'
                    }
                })
            },
            viewInvoice(inv){
                this.$store.dispatch('navigationStore/openSlideOut', {
                    component: 'invoice',
                    props: {
                        invoice_id: inv.id,
                    }
                })
                //
                // this.selected = inv;
                // this.showView = true;
            },
            search(){

                var data = this.filters;
                if(data){
                    data.offset = data.page == 0 ? 0 : ((data.page-1) * data.limit);
                }
                api.post(this, api.AGING_DETAIL, data).then(r => {
                    this.invoices = r.invoices;
                    this.result_count = r.result_count;
                });
            }
        },
        mixins:[tableFilters, tableKeys],
    }



</script>
