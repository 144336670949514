
export const tableKeys = {
	data() {
		return {
			cols: [

				{
					label: "Address",
					key: "address",
					sortable:true,
					width: 150,
					showSmall: true
				},
        {
          label: "Tenant Name",
          key: "tenant_name",
          sortable:true,
          width: 150
        },
				{
					label: "Due Date",
					key: "due",
					sortable:true,
					width: 150
				},
				{
					label: "Balance",
					key: "balance",
					sortable:true,
					width: 150,
					showSmall: true
				}
			],
			filters:{
				search:{
					property_id: [],
					start_date:'',
					end_date: '',
					tenant_name: '',
					number: '',
					address: '',
					due: '',
					amount: '',
					balance: '',
					status:[]
				},
				columns:[
					'due',
					'address',
          'tenant_name',
					'balance'
				],
				sort:{
					field: 'balance',
					dir: 'DESC'
				},
				page: 1,
				limit:25,
				offset:0
			},
		}
	}
};
